/**
 * Add back to top functionality
 */

const backToTop = () => {
  const backTop = document.querySelector('.top__back')

  backTop.addEventListener('click', () => {
    document.body.scrollIntoView({ behavior: 'smooth' })
  })
}

export default backToTop
