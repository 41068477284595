import backToTop from './components/backToTop'
import anchorSmoothScroll from './components/anchorSmoothScroll'
import accordionToggle from './components/accordionToggle'
import showNavigationOnScroll from './components/showNavigationOnScroll'
import navigationToggle from './components/navigationToggle'
import viewportFixOnMobile from './components/viewportFixOnMobile'
import autofillAddressByZip from './components/autofillAddressByZip'
import popupClose from './components/popupClose'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    backToTop()
    anchorSmoothScroll()
    accordionToggle()
    showNavigationOnScroll()
    navigationToggle()
    viewportFixOnMobile()
    autofillAddressByZip()
    popupClose()
  },
  false
);
