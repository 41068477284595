/**
 * Toggle navigation on SP view
 */

const navigationToggle = () => {
  const navigation = document.querySelector('.navigation')
  const navigationMenu = document.querySelector('.navigation__menu')
  const navigationSP = document.querySelector('.navigation__sp-container')

  navigationMenu.addEventListener('click', () => {
    navigation.classList.toggle('navigation--toggle')
    navigationSP.classList.toggle('navigation__sp-container--active')
    document.body.classList.toggle('overflow')
  })
}

export default navigationToggle
