/**
  * Accordion toggling
 */

const accordionToggle = () => {
  const accordion = document.querySelectorAll('.js-accordion')

  for(let item of accordion) {
    item.addEventListener('click', () => {
      item.classList.toggle('active')
      let content = item.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null
      } else {
        content.style.maxHeight = content.scrollHeight + 'px'
      }
    })
  }
}

export default accordionToggle
