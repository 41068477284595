/**
 * Close popup dialog
 */

const popupClose = () => {
  const popup =  document.querySelector('.popup--login');

  popup.querySelector('.popup__close').addEventListener('click', function() {
    popup.classList.remove('active');
  });
}


export default popupClose