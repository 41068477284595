/**
 * Autofill an adress on click
 */

const backToTop = () => {
  const autofillButton = document.querySelector('.contact__autofill')
  const zipcode = document.querySelector('input[name="postal-code"]')

  if(autofillButton) {
    autofillButton.addEventListener('click', () => {
      AjaxZip3.zip2addr(zipcode,'','address','address');
    })
  }
}

export default backToTop
