/**
 * Shows the navigation on scroll
 */

const onScrollEvent = navigation => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop
  if(scrollTop >= 3168) {
    navigation.classList.add('navigation--active')
  } else {
    navigation.classList.remove('navigation--active')
  }
}

const showNavigationOnScroll = () => {
  const navigation = document.querySelector('.js-navigation')

  if(navigation) {
    onScrollEvent(navigation)

    window.addEventListener('scroll', () => {
      onScrollEvent(navigation)
    })
  }
}

export default showNavigationOnScroll
