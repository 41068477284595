/**
 * Fix for viewports on mobile
 */
const changeViewport = () => {
  let vh = window.innerHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const viewportFixOnMobile = () => {
  changeViewport()

  window.addEventListener('resize', () => {
    changeViewport()
  })
}

export default viewportFixOnMobile
